export const reverseDate = (date) => {
  return date.split('-').reverse().join('-');
}

export const images = {
  accessories: 'akcesoria.png',
  battery: 'akumulatory.png',
  filters: 'filtry.png',
  videoRepair: 'wideonaprawa.png',
  wipers: 'wycieraczki.png',
  springPremiumPackages: 'wiosennepakietypremium.png',
  airClean: 'czyszczenie_klimatyzacji.png'
}

export const pdfs = {
  accessories: '/pdf/regulamin_konkurs_akcesoria_w_serwisie_2024.pdf',
  battery: '/pdf/regulamin_akumulatory_1_10_2024-28_02_2025.pdf',
  filters: '/pdf/regulamin_konkurs_filtry_antyalergiczne_2024.pdf',
  videoRepair: '/pdf/regulamin_konkurs_wideo_naprawa_2024.pdf',
  wipers: '/pdf/regulamin_konkurs_wycieraczki_oryginalne_2024.pdf',
  springPremiumPackages: '/pdf/regulamin_konkurs_wiosenne_pakiety_premium_2024.pdf',
  airClean: '/pdf/regulamin_konkurs_czyszczenie_klimatyzacji_2024.pdf'
}